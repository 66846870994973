import { Injectable } from "@angular/core";
import { CRUDService } from "../base/crud.service";
import { Driver } from "../../classes/driver";
import { environment } from "../../../environments/environment";
import { Http } from "@angular/http";
import { User } from "../../classes/user";
import { AuthGuard } from "../../guards/auth.guard";
import { MotoristasVeiculos } from "../../classes/motoristas-veiculos";
import { RESTResponseVO } from "../../classes/restresponsevo";
import { DriverStatus } from "../../classes/driver-status";
import { Motive } from "../../classes/motive";
import { MotoristaChatVO } from "../../classes/motorista-chat-vo";

@Injectable()
export class DriverService extends CRUDService<Driver> {
    localAuthGuard: AuthGuard;
    constructor(http: Http,
    authGuard: AuthGuard) {
        super(http, environment.baseURL + 'App/v2/web/motoristas', true);
        this.localAuthGuard = authGuard;
        
        this.setListUrl();
    }

    addVehicle(item : MotoristasVeiculos){
        var url: string = environment.baseURL + 'App/v2/web/motoristas/veiculos/';
        return this.http
            .post(url, JSON.stringify(item), { headers: this.headers })
            .toPromise()
            .then(response => {
                if (this.restResponseWrapper) {
                    return response.json() as RESTResponseVO<MotoristasVeiculos>;
                }
            })
            .catch(this.handleError);
    }

    editVehicle(item : MotoristasVeiculos){
        var url: string = environment.baseURL + 'App/v2/web/motoristas/veiculos/' + item.id;
        return this.http
            .put(url, JSON.stringify(item), { headers: this.headers })
            .toPromise()
            .then(response => {
                if (this.restResponseWrapper) {
                    return response.json() as RESTResponseVO<MotoristasVeiculos>;
                }
            })
            .catch(this.handleError);
    }

    removeVehicle(item : MotoristasVeiculos): Promise<RESTResponseVO<string>> {
        const url: string = environment.baseURL + 'App/v2/web/motoristas/veiculos/' + item.id;
        return this.http.delete(url)
            .toPromise()
            .then(response => {
                if (this.restResponseWrapper) {
                    return response.json() as RESTResponseVO<string>;
                }
            })
            .catch(this.handleError);
    }

    setListUrl(){
        var loggedUser = this.localAuthGuard.loggedUser();
        var company: number = loggedUser.selectedCompany.id;
        var branch: string = loggedUser.selectedBranch.id === 0 && '0' || loggedUser.selectedBranch.id.toString();

        this.listURL = environment.baseURL + 'App/v2/web/motoristas/findAllListForView/' + company + '/' + branch;
    }

    getList(): Promise<RESTResponseVO<Driver[]>> {
        this.setListUrl();
        return super.getList();
    }

    getMotoristas(): Promise<RESTResponseVO<Driver[]>> {
        var loggedUser = this.localAuthGuard.loggedUser();
        var company: number = loggedUser.selectedCompany.id;
        var branch: string = loggedUser.selectedBranch.id === 0 && '0' || loggedUser.selectedBranch.id.toString();
        let url: string = environment.baseURL + 'App/v2/web/motoristas/lista/' + company + '/' + branch;
        return this.http.get(url)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<Driver[]>;
            })
            .catch(this.handleError);
    }

    getListMinimo(): Promise<RESTResponseVO<Driver[]>> {
        var loggedUser = this.localAuthGuard.loggedUser();
        var company: number = loggedUser.selectedCompany.id;
        var branch: string = loggedUser.selectedBranch.id === 0 && '0' || loggedUser.selectedBranch.id.toString();
        let url: string = environment.baseURL + 'App/v2/web/motoristas/listaMotoristaMinimo/' + company + '/' + branch;
        return this.http.get(url)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<Driver[]>;
            })
            .catch(this.handleError);
    }

    createMotive(driverStatus :DriverStatus):Promise<RESTResponseVO<DriverStatus>>{
        const url: string = environment.baseURL + 'App/v2/web/motoristas/situacao/';
        return this.http
            .post(url, JSON.stringify(driverStatus), { headers: this.headers })
            .toPromise()
            .then(response => {
                if (this.restResponseWrapper) {
                    return response.json() as RESTResponseVO<DriverStatus>;
                }
            })
            .catch(this.handleError);
    }

    getMotoristasChat(): Promise<RESTResponseVO<MotoristaChatVO[]>> {
        var loggedUser = this.localAuthGuard.loggedUser();
        var company: number = loggedUser.selectedCompany.id;
        var branch: string = loggedUser.selectedBranch.id === 0 && '0' || loggedUser.selectedBranch.id.toString();
        let url: string = environment.baseURL + 'App/v2/web/motoristas/listaMotoristaChat/' + company + '/' + branch;
        return this.http.get(url)
            .toPromise()
            .then(response => {
                return response.json() as RESTResponseVO<MotoristaChatVO[]>;
            })
            .catch(this.handleError);
    }
}