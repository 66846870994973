import { Address } from './address';
import { Parametro } from './parametro';
import { IDEntity } from './IDEntity';

export class Company extends IDEntity {
  nome: string;
  displayName: string;
  cnpj: string;
  inscricaoMunicipal: string;
  inscricaoEstadual: string;
  empresaPrincipal: number;
  responsavel: string;
  telefone: string;
  email: string;
  status:boolean;
  dataValidade: Date;
  quantidadeUsuarios: number;
  numeroLicenca: string;
  boshServiceURL: string;
  interjourneyCompensationLimit: number;
  interjourneyCompensateStandby: boolean;
  workedDaysDSR: number;
  missingDaysDSR: number;
  considersInterjourney: boolean;
  halfInterjourney: boolean;
  sascarintegration: boolean;
  frequencyreading: number;
  habilitarViaSat: boolean;
  habilitarViam:boolean;

  endereco: Address;
  parametro: Parametro;
  tipoSistema: String;

  idCriador: number;

  constructor() {
    super();
    this.endereco = new Address();
  }
}
