import { Component, OnInit } from '@angular/core';

import { AuthGuard } from '../../guards/auth.guard';
import { User } from '../../classes/user';
import { RESTResponseVO } from '../../classes/restresponsevo';
import { Router } from '@angular/router';
import { Company } from '../../classes/company';
import { Branch } from '../../classes/branch';
import { IDNameTuple } from '../../classes/id-name-tuple';

@Component({
    selector: 'app-new-context-picker',
    templateUrl: './new-context-picker.component.html',
    styleUrls: ['./new-context-picker.component.css']
})
export class NewContextPickerComponent implements OnInit {
    public stateName: String;
    public loggedUser: User;
    public selectedCompany: IDNameTuple;
    public selectedBranch: IDNameTuple;
    public showCompanyPicker: boolean;
    public showBranchPicker: boolean;
    empresas: any[] = [];
    unidades: any[] = [];
    ano = new Date().getFullYear();

    public showedBranches: IDNameTuple[];

    public errorMessage: String;

    constructor(private authGuard: AuthGuard, private router: Router) { }

    ngOnInit() {
        this.loggedUser = this.authGuard.loggedUser();

        if (!this.loggedUser.companyList || this.loggedUser.companyList.length === 0) {
            this.errorMessage = 'Usuário não possui empresa e/ou unidades vinculadas!';
            return;
        }

        this.selectedBranch = undefined;
        this.selectedCompany = undefined;

        this.empresas = this.loggedUser.companyList;

        if (this.loggedUser.companyList.length > 0) {
            this.showBranchPicker = false;
            this.showCompanyPicker = true;
        } else {
            this.selectedCompany = this.loggedUser.companyList[0];
            this.showedBranches = this.loggedUser.branchMap[this.selectedCompany.name];
            this.unidades = this.loggedUser.branchMap[this.selectedCompany.name];
            this.showBranchPicker = true;
            this.showCompanyPicker = false;
        }
    }

    onCompanySelected(event) {
        this.showCompanyPicker = true;
        if (event === undefined) {
            this.selectedCompany = undefined;
            this.showBranchPicker = false;
        } else {
            this.selectedCompany = event;
            this.showBranchPicker = true;
            this.unidades = this.loggedUser.branchMap[event.name];
        }
    }

    onBranchSelected(event) {
        if (event === undefined) {
            this.selectedBranch = undefined;
        } else {
            this.selectedBranch = event;
        }
    }

    public onBtnLogout(): void {
        this.authGuard.logout();
    }

    public onBtnNext(): void {
        if (this.showCompanyPicker) {
            this.showCompanyPicker = false;
            if (!this.selectedBranch) {
                this.showedBranches = this.loggedUser.branchMap[this.selectedCompany.name];
                this.showBranchPicker = true;
            }
        } else {
            this.showBranchPicker = false;
        }
    }

    public onBtnConfirm(): void {
        this.loggedUser.selectedBranch = this.selectedBranch;
        this.loggedUser.selectedCompany = this.selectedCompany;

        for (let c in this.loggedUser.branchMap) {
            this.loggedUser.branchMap[c].forEach(function (b: IDNameTuple) { b.css = ''; });
        }
        this.loggedUser.companyList.forEach(function (c: IDNameTuple) { c.css = ''; });

        localStorage.setItem('currentUser', JSON.stringify(this.loggedUser));
        this.router.navigate(['adm'], { skipLocationChange: true });
    }

    public resetBranch(): void {
        this.toggleBranch(undefined);
        this.selectedBranch = undefined;
        this.showBranchPicker = true;
    }

    public resetCompany(): void {
        this.toggleCompany(undefined);
        this.selectedCompany = undefined;
        this.showCompanyPicker = true;
    }

    public toggleBranch(branch: IDNameTuple): void {
        this.loggedUser.branchMap[this.selectedCompany.name].forEach(function (value) {
            if (branch === value) {
                if (this.selectedBranch !== value) {
                    value.css = 'alert-success';
                    this.selectedBranch = value;
                } else {
                    value.css = '';
                    this.selectedBranch = undefined;
                }
            } else {
                value.css = '';
            }
        }, this);
    }

    public toggleCompany(company: IDNameTuple): void {
        this.loggedUser.companyList.forEach(function (value) {
            if (company === value) {
                if (this.selectedCompany !== value) {
                    value.css = 'alert-success';
                    this.selectedCompany = value;
                } else {
                    value.css = '';
                    this.selectedCompany = undefined;
                }
            } else {
                value.css = '';
            }
        }, this);
    }
}
