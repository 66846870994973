import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DatePipe } from '@angular/common';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';

import { Address } from '../../../../classes/address';
import { Company } from '../../../../classes/company';
import { Parametro } from '../../../../classes/parametro';
import { City } from '../../../../classes/city';
import { CityService } from '../../../../services/crud/city.service';
import { CompanyService } from '../../../../services/crud/company.service';

import { BaseFormComponent } from '../../../base/base-form/base-form.component';
import { RESTResponseVO } from '../../../../classes/restresponsevo';
import { AuthGuard } from 'app/guards/auth.guard';


@Component({
  selector: 'app-company-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css'],
  providers: [DatePipe]
})
export class CompanyFormComponent extends BaseFormComponent<Company> {
  companyList: Company[];

  companyFormatter = (x: Company) => x.nome;

  searchCompany = (text$: Observable<string>) =>
    text$
      .debounceTime(200)
      .distinctUntilChanged()
      .map(term =>
        term.length < 2
          ? []
          : this.companyList
              .filter(
                v => v.nome.toLowerCase().indexOf(term.toLowerCase()) > -1
              )
              .slice(0, 10)
      );

  constructor(
    private cityService: CityService,
    private companyService: CompanyService,
    private datePipe: DatePipe,
    private authGuard: AuthGuard,
    route: ActivatedRoute,
    router: Router
  ) {
    super(route, router, 'adm/company/list');
  }

  assembleObjectFromForm(): void {}

  loadAuxiliaryLists(): void {
    this.companyService
      .getList()
      .then(response => (this.companyList = response.response));
  }

  loadObject(): void {
    if (this.activeID && this.activeID !== 0) {
      this.companyService.getById(this.activeID).then(response => {
        const resultItem: Company = response.response;

        if (!resultItem.endereco) {
          resultItem.endereco = new Address();
        }

        if(!resultItem.parametro){
          resultItem.parametro = new Parametro();
        }

        if (resultItem.dataValidade) {
          const tmpDate = new Date(resultItem.dataValidade);
          resultItem.dataValidade = new Date(
            this.datePipe.transform(resultItem.dataValidade, 'YYYY-MM-DD')
          );
        }

        this.activeObject = resultItem;
      });
    }
    this.loading = false;
  }

  initObject(): void {
    this.activeObject = new Company();

    this.activeObject.parametro = new Parametro();
    this.loading = false;
  }

  doCreate(t: Company): Promise<RESTResponseVO<Company>> {
    const loggedUserId = this.authGuard.loggedUser().id;
    t.idCriador = loggedUserId;
    return this.companyService.create(t);

    return this.companyService.create(t);
  }

  doEdit(t: Company): Promise<RESTResponseVO<Company>> {
    return this.companyService.update(t);
  }

  doDelete(t: Company): Promise<RESTResponseVO<string>> {
    return this.companyService.delete(t.id);
  }

  companyTemplate(r) {
    return r.nome;
  }

  compareWith(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  setActiveID(): void {
    this.activeID = this.activeObject.id;
  }
}
